body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas,
        'Courier New', monospace;
}

.navbar {
    margin-bottom: 20px;
}

.card {
    height: 600px;
    margin-bottom: 30px;
}

.card-img-container {
    position: relative;
    display: inline-block;
}

.card-img-container img {
    max-height: 250px;
}

.card-img-container .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.card-img-container .overlay .badge {
    color: white;
    margin-left: 10px;
    margin-top: 10px;
}
